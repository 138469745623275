import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="cloudflare"
    name="CloudFlare R2"
    shortDescription="Store your backups on Cloudflare R2 Storage."
    website={"https://developers.cloudflare.com/r2/"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="cloudflare-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Cloudflare R2')}
    metaDescription={meta.description.replace('##provider##', 'Cloudflare R2')}
  >
    <IntegrationStorageContent
      keyRef="cloudflare"
      service="CloudFlare R2"
      provider="CloudFlare"
      contentBlocks={[{title: 'Your backups on a Rapid and Reliable S3-Compatible Object Storage', text: 'Store your backups on your Cloudflare R2 object storage.\n' +
          'R2 gives you the freedom to create the multi-cloud architectures you desire with an S3-compatible distributed object storage.\n' +
          'Create your bucket, select your region, and configure your backups.\n' +
          'Data is securely saved on your S3 bucket powered by Cloudflare and distributed across its network.'}]}
      learnTags={['cloudflare']}
    />
  </IntegrationTemplate>
)

export default ContentPage
